// Gatsby supports TypeScript natively!
import React, { useEffect } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

const Sponsored = () => {
  useEffect(() => {
    window.slider()
  }, [])
  return (
    <Layout title="Sponsored Research" show_bread={true}>
      <SEO title="Sponsored Research"/>
      <div className={"section mcb-section equal-height"} style={{ paddingTop: "100px", paddingBottom: "60px" }}>
        <div className={"section_wrapper mcb-section-inner"}>
          <div className={"wrap mcb-wrap one  valign-top clearfix"}>
            <div className={"mcb-wrap-inner"}>
              <div className="wrap mcb-wrap one valign-top move-up clearfix" style={{ marginTop: "-64px" }}>
                <div className="mcb-wrap-inner">
                  <div className="column mcb-column one-third column_column ">
                    <div className="column_attr clearfix align_center" style={{ padding: "0 10%" }}>
                      <div className="image_frame image_item no_link scale-with-grid alignnone no_border">
                        <div className="image_wrapper rounded-img-nh">
                          <img className="scale-with-grid" src={"/images/sponsored/2.svg"} width="200" height="200"/>
                        </div>
                      </div>
                      <h4 className="mb-20">Research Institucional</h4>
                      <p>
                        Proveemos documentos de análisis bajo una estructura
                        institucional.
                      </p>
                      <p>En contraste, para obtener la cobertura de alguna casa
                        de bolsa o banco, se tiene que dar a cambio negocio,
                        como por ejemplo <em>fees</em> de Banca de Inversión.</p>
                    </div>
                  </div>
                  <div className="column mcb-column one-third column_column ">
                    <div className="column_attr clearfix align_center" style={{ padding: "0 10%" }}>
                      <div className="image_frame image_item no_link scale-with-grid alignnone no_border">
                        <div className="image_wrapper rounded-img-nh">
                          <img className="scale-with-grid" src={"/images/sponsored/3.svg"} width="200" height="200"/>
                        </div>
                      </div>
                      <h4 className="mb-20">Alcance</h4>
                      <p>
                        Aumentamos la bursatilidad de la acción bajo análisis
                        vía el incremento de su visibilidad en el mercado.
                      </p>
                      <p>Con alcance nacional e internacional, distribuimos a
                        Inversionistas Institucionales y <em>Retail</em>, mediante
                        plataformas especializadas y alianzas estratégicas.</p>
                    </div>
                  </div>
                  <div className="column mcb-column one-third column_column ">
                    <div className="column_attr clearfix align_center" style={{ padding: "0 10%" }}>
                      <div className="image_frame image_item no_link scale-with-grid alignnone no_border">
                        <div className="image_wrapper rounded-img-nh-nh">
                          <img className="scale-with-grid" src={"/images/sponsored/1.svg"} width="200" height="200"/>
                        </div>
                      </div>
                      <h4 className="mb-20">Independencia</h4>
                      <p>
                        Damos total transparencia al mercado sobre nuestro
                        método y métricas de valuación.
                      </p>
                      <p>Apalache revela a los usuarios que percibe una
                        remuneración por sus servicios de análisis.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <p text-align="center">Los emisores small- mid- cap (market cap. menos de US$1 billón) obtienen beneficios tangibles del servicio de análisis antes de 6 meses.</p>
          <p text-align="center">Para mayor información sobre nuestro servicio de <em>Sponsored Research</em> favor de contactarse con <a href="mailto:jorge.lagunas@apalache.mx">jorge.lagunas@apalache.mx</a></p>
        </div>
      </div>
    </Layout>
  )
}

export default Sponsored
